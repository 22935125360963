// eslint-disable-next-line
/* eslint-disable */
import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

@Module
export default class AgendaAmbulanciaModule extends VuexModule {
  errors = {};
  agenda = {};
  response = {};
  searchCompnay = "";
  responseAgendaPago = {};
  agendas = [] as Array<unknown>;

  agendasAnio = [] as Array<unknown>;
  agendaRecientes = [] as Array<unknown>;

  misAgendas = [] as Array<unknown>;
  agendasLaboratorio = [] as Array<unknown>;

  get currentAgenda(): {} {
    return this.agenda;
  }
  get currentAgendas(): Array<unknown> {
    return this.agendas;
  }
  get currentAgendaRecientes(): Array<unknown> {
    return this.agendaRecientes;
  }
  get currentAgendasAnio(): Array<unknown> {
    return this.agendasAnio;
  }
  get currentMisAgendas(): Array<unknown> {
    return this.misAgendas;
  }
  get currentAgendasLaboratorio(): Array<unknown> {
    return this.agendasLaboratorio;
  }
  get getErrorAgendas() {
    return this.errors;
  }
  get getResponseAgenda() {
    return this.response;
  }
  get getResponseAgendaPago(): {} {
    return this.responseAgendaPago;
  }
  get getSearchCompnay(): {} {
    return this.searchCompnay;
  }

  @Mutation
  [Mutations.SET_AGENDA_RESPONSE](res) {
    this.response = res;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_ERROR_AGENDA](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AGENDA](agenda) {
    this.agenda = agenda;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_SEARCH_COMPANY](compnay) {
    this.searchCompnay = compnay;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_AGENDA_ANIO](agendas) {
    this.agendasAnio = agendas;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_AGENDA_PAGO](agenda) {
    this.responseAgendaPago = agenda;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_AGENDAS](agendas) {
    this.agendas = agendas;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_AGENDA_RECIENTES](agendas) {
    this.agendaRecientes = agendas;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_MIS_AGENDAS](agendas) {
    this.misAgendas = agendas;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_AGENDAS_LABORATORIO](agendas) {
    this.agendasLaboratorio = agendas;
    this.errors = {};
  }

  @Action
  [Actions.LIST_AGENDAS](form) {
    console.log('asdasdasdasdasd');
    return ApiService.get('/agendaAmbulancias')
      .then(({ data }) => {
        console.log('LIST_AGENDASS data: ', data);
        this.context.commit(Mutations.SET_AGENDAS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_AGENDA, response.data.errors);
      });
  }

  @Action
  [Actions.LIST_AGENDA_ANIO](anio) {
    return ApiService.get('/agendaAmbulancias-anio/' + anio)
      .then(({ data }) => {
        console.log('LIST_AGENDA_ANIO data: ', data);
        this.context.commit(Mutations.SET_AGENDA_ANIO, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_AGENDA, response.data.errors);
      });
  }

  @Action
  [Actions.LIST_AGENDA_RECIENTES](form) {
    return ApiService.get('/agendaAmbulancias-recientes')
      .then(({ data }) => {
        console.log('LIST_AGENDA_RECIENTES data: ', data);
        this.context.commit(Mutations.SET_AGENDA_RECIENTES, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_AGENDA, response.data.errors);
      });
  }

  @Action
  [Actions.GET_AGENDA](_id) {
    return ApiService.get(`get-agendaAmbulancia?_id=${_id}`)
      .then(({ data }) => {
        console.log('GET_AGENDA data: ', data);
        this.context.commit(Mutations.SET_AGENDA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_AGENDA, response.data.errors);
      });
  }

  @Action
  [Actions.LIST_MIS_AGENDAS](idUser) {
    let url = '/mis-ordenes-agendaAmbulancia';
    if (idUser != null) {
      url = '/mis-ordenes-agendaAmbulancia?idUser=' + idUser;
    }

    return ApiService.get(url)
      .then(({ data }) => {
        console.log('LIST_MIS_AGENDAS data: ', data);
        this.context.commit(Mutations.SET_MIS_AGENDAS, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_AGENDA, response.data.errors);
      });
  }

  @Action
  [Actions.LIST_AGENDAS_LABORATORIO](params) {
    return ApiService.get('/ordenes-proveedor-agendaAmbulancia')
      .then(({ data }) => {
        console.log('LIST_AGENDAS_LABORATORIO data: ', data);
        this.context.commit(Mutations.SET_AGENDAS_LABORATORIO, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_AGENDA, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_AGENDA](form) {
    console.log('UPDATE_AGENDA form: ', form);
    return ApiService.post('/update-agendaAmbulancia', form)
      .then(({ data }) => {
        console.log('UPDATE_AGENDA data: ', data);
        this.context.commit(Mutations.SET_AGENDA_RESPONSE, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
      });
  }

  @Action
  [Actions.AGENDA_SEND_PAGO](form) {
    console.log('AGENDA_SEND_PAGO form: ', form);
    return ApiService.post(`update-agendaAmbulancia-panel`, form)
      .then(({ data }) => {
        console.log('AGENDA_SEND_PAGO data: ', data);
        this.context.commit(Mutations.SET_AGENDA_PAGO, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
      });
  }
  // @Action
  // [Actions.AGENDA_SEND_PAGO](form) {
  //   console.log("AGENDA_SEND_PAGO form: " , form);
  //   return ApiService.post("/order-send-pago", form).then(({ data }) => {
  //     console.log("AGENDA_SEND_PAGO data: " , data);
  //     this.context.commit(Mutations.SET_AGENDA_PAGO, data);
  //   }).catch(({ response }) => {
  //     this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
  //   });
  // }

  @Action
  [Actions.CREATE_AGENDA](form) {
    console.log('CREATE_AGENDA form: ', form);
    return ApiService.post('/agendaAmbulancia-panel', form)
      .then(({ data }) => {
        console.log('CREATE_AGENDA data: ', data);
        this.context.commit(Mutations.SET_AGENDA_RESPONSE, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
      });
  }

  @Action
  [Actions.DELETE_AGENDA](form) {
    console.log('DELETE_AGENDA form: ', form);
    return ApiService.post('/delete-agendaAmbulancia', form)
      .then(({ data }) => {
        console.log('DELETE_AGENDA data: ', data);
        this.context.commit(Mutations.SET_AGENDA_RESPONSE, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_COMPLETADO_CITA](form) {
    console.log('UPDATE_COMPLETADO_CITA form: ', form);
    return ApiService.post('/update-agenda-completado', form)
      .then(({ data }) => {
        console.log('UPDATE_COMPLETADO_CITA data: ', data);
        this.context.commit(Mutations.SET_AGENDA_RESPONSE, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
      });
  }
}
