enum Actions {
  // action types
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  LOGIN_GOOGLE = 'login_google',
  LOGOUT = 'logout',
  REGISTER = 'register',
  UPDATE_USER = 'updateUser',
  FORGOT_PASSWORD = 'forgotPassword',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  SET_THEME_MODE_ACTION = 'setThemeModeAction',
  LIST_CLIENTES = 'listClientes',
  CREATE_CLIENTE = 'createCliente',
  UPDATE_CLIENTE = 'updateCliente',
  DELETE_CLIENTE = 'deleteCliente',
  LIST_ORDENES = 'listOrdenes',
  LIST_AGENDAS = 'listAgendas',
  GET_AGENDA = 'getAgenda',
  LIST_ORDENES_RECIENTES = 'listOrdenesRecientes',
  LIST_AGENDA_RECIENTES = 'listAgendaRecientes',
  LIST_ORDENES_ANIO = 'listOrdenesAnio',
  LIST_AGENDA_ANIO = 'listAgendaAnio',
  LIST_MIS_ORDENES = 'listMisOrdenes',
  LIST_MIS_AGENDAS = 'listMisAgendas',
  ORDEN_SEND_PAGO = 'ordenSendPago',
  AGENDA_SEND_PAGO = 'agendaSendPago',
  LIST_ORDENES_LABORATORIO = 'listOrdenesLaboratorio',
  LIST_AGENDAS_LABORATORIO = 'listAgendasLaboratorio',
  UPDATE_ORDEN = 'updateOrden',
  UPDATE_AGENDA = 'updateAgenda',
  CREATE_ORDEN = 'createOrden',
  CREATE_AGENDA = 'createAgenda',
  DELETE_ORDEN = 'deleteOrden',
  DELETE_AGENDA = 'deleteAgenda',
  LIST_LABORATORIOS = 'listLaboratorios',
  CREATE_LABORATORIO = 'createLaboratorio',
  UPDATE_LABORATORIO = 'updateLaboratorio',
  UPDATE_COMPLETADO_CITA = 'updateCitaCompletado',
  DELETE_LABORATORIO = 'deleteLaboratorio',
  LIST_PRODUCTOS_BY_ID_SUCURSAL = 'listProductosByIdSucursal',
  LIST_PRODUCTOS = 'listProductos',
  CREATE_PRODUCTO = 'createProducto',
  UPDATE_PRODUCTO = 'updateProducto',
  DELETE_PRODUCTO = 'deleteProducto',
  LIST_CATEGORIAS = 'listCategorias',
  LIST_AMBULANCIAS = 'listAmbulancias',
  LIST_PROVEEDORES = 'listProveedores',
  CREATE_CATEGORIA = 'createCategoria',
  CREATE_AMBULANCIA = 'createAmbulancia',
  CREATE_PROVEEDOR = 'createProveedor',
  UPDATE_CATEGORIA = 'updateCategoria',
  UPDATE_AMBULANCIA = 'updateAmbulancia',
  UPDATE_PROVEEDOR = 'updateProveedor',
  UPDATE_ORDEN_FECHA = 'updateFechaOrden',
  DELETE_CATEGORIA = 'deleteCategoria',
  DELETE_AMBULANCIA = 'deleteCategoria',
  DELETE_PROVEEDOR = 'deleteProveedor',
  LIST_SUCURSALES = 'listSucursales',
  LIST_SUCURSALESS = 'listSucursaless',
  CREATE_SUCURSAL = 'createSucursal',
  UPDATE_SUCURSAL = 'updateSucursal',
  DELETE_SUCURSAL = 'deleteSucursal',
  GET_LABORATORIO = 'getLaboratorio',
  UPDATE_PERFIL = 'updatePerfil',
  GET_PROVEEDOR = 'getProveedor',
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_USER = 'setUser',
  SET_PASSWORD = 'setPassword',
  SET_ERROR = 'setError',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  SET_LAYOUT_CONFIG_PROPERTY = 'setLayoutConfigProperty',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_THEME_MODE_MUTATION = 'setThemeModeMutation',
  SET_CLIENTES = 'setClientes',
  SET_CLIENTE_RESPONSE = 'setResponseCliente',
  SET_ORDEN = 'setOrden',
  SET_AGENDA = 'setAgenda',
  SET_SEARCH_COMPANY = 'setSearchCompany',
  SET_ORDENES = 'setOrdenes',
  SET_AGENDAS = 'setAgendas',
  SET_ORDENES_RECIENTES = 'setOrdenesRecientes',
  SET_AGENDA_RECIENTES = 'setAgendaRecientes',
  SET_ORDEN_RESPONSE = 'setResponseOrden',
  SET_AGENDA_RESPONSE = 'setResponseAgenda',
  SET_MIS_ORDENES = 'setMisOrdenes',
  SET_MIS_AGENDAS = 'setMisAgendas',
  SET_ORDENES_LABORATORIO = 'setOrdenesLaboratorio',
  SET_AGENDAS_LABORATORIO = 'setAgendasLaboratorio',
  SET_ORDENES_ANIO = 'setOrdenesAnio',
  SET_AGENDA_ANIO = 'setAgendaAnio',
  SET_ORDEN_PAGO = 'setOrdenPago',
  SET_AGENDA_PAGO = 'setAgendaPago',
  SET_PRODUCTOS = 'setProductos',
  SET_PRODUCTO = 'setProductos',
  SET_PRODUCTOS_BY_ID_SUCURSAL = 'setProductoByIdSucursal',
  SET_PRODUCTO_RESPONSE = 'setResponseProducto',
  SET_LABORATORIO = 'setLaboratorio',
  SET_LABORATORIOS = 'setLaboratorios',
  SET_LABORATORIO_RESPONSE = 'setResponseLaboratorio',
  SET_CATEGORIAS = 'setCategorias',
  SET_AMBULANCIAS = 'setAmbulancias',
  SET_PROVEEDORES = 'setProveedores',
  SET_CATEGORIA = 'setCategoria',
  SET_CATEGORIA_RESPONSE = 'setResponseCategoria',
  SET_AMBULANCIA_RESPONSE = 'setResponseAmbulancia',
  SET_PROVEEDOR_RESPONSE = 'setResponseProveedor',
  SET_ERROR_PRODUCT = 'setErrorProduct',
  SET_ERROR_ORDEN = 'setErrorOrden',
  SET_ERROR_AGENDA = 'setErrorAgenda',
  SET_ERROR_LAB = 'setErrorLab',
  SET_ERROR_CLIENT = 'setErrorClient',
  SET_ERROR_CAT = 'setErrorCat',
  SET_SUCURSALES = 'setSucursales',
  SET_SUCURSALESS = 'setSucursaless',
  SET_SUCURSAL = 'setSucursal',
  SET_SUCURSAL_RESPONSE = 'setResponseSucursal',
  SET_PERFIL = 'setPerfil',
  SET_PROVEEDOR = 'setProveedor',
}

export { Actions, Mutations };
