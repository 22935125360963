// eslint-disable-next-line
/* eslint-disable */
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class ProveedorModule extends VuexModule {
  errors = {};
  response = {};
  proveedor = {};
  proveedores = [] as Array<unknown>;

  get currentProveedoress(): Array<unknown> {
    return this.proveedores;
  }
  get currentProveedor() {
    return this.proveedor;
  }
  get getErrorProveedoress() {
    return this.errors;
  }
  get getResponseProveedores() {
    return this.response;
  }

  @Mutation
  [Mutations.SET_ERROR_CAT](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_PROVEEDORES](proveedore) {
    this.proveedores = proveedore;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_PROVEEDOR](proveedor) {
    this.proveedor = proveedor;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_PROVEEDOR_RESPONSE](res) {
    this.response = res;
    this.errors = {};
  }

  @Action
  [Actions.GET_PROVEEDOR](proveedor_id) {
    return ApiService.get(`/get-proveedor?_id=${proveedor_id}`).then(({ data }) => {
      console.log("GET_PROVEEDOR data: " , data);
      this.context.commit(Mutations.SET_PROVEEDOR, data);
    }).catch(({ response }) => {
      this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
    });
  }
  @Action
  [Actions.LIST_PROVEEDORES](form) {
    return ApiService.get("/proveedores").then(({ data }) => {
      console.log("LIST_PROVEEDORESS data: " , data);
      this.context.commit(Mutations.SET_PROVEEDORES, data);
    }).catch(({ response }) => {
      this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
    });
  }
  
  @Action
  [Actions.CREATE_PROVEEDOR](form) {
    console.log("CREATE_PROVEEDOR form: " , form);
    return ApiService.post("/proveedor", form).then(({ data }) => {
      console.log("CREATE_PROVEEDOR data: " , data);
      this.context.commit(Mutations.SET_PROVEEDOR_RESPONSE, data);
    }).catch(({ response }) => {
      this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
    });
  }
  
  @Action
  [Actions.UPDATE_PROVEEDOR](form) {
    console.log("UPDATE_PROVEEDOR form: " , form);
    return ApiService.post("/update-proveedor", form).then(({ data }) => {
      console.log("UPDATE_PROVEEDOR data: " , data);
      this.context.commit(Mutations.SET_PROVEEDOR_RESPONSE, data);
    }).catch(({ response }) => {
      this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
    });
  }
  
  @Action
  [Actions.DELETE_PROVEEDOR](form) {
    console.log("DELETE_PROVEEDOR form: " , form);
    return ApiService.post("/delete-proveedor", form).then(({ data }) => {
      console.log("DELETE_PROVEEDOR data: " , data);
      this.context.commit(Mutations.SET_PROVEEDOR_RESPONSE, data);
    }).catch(({ response }) => {
      this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
    });
  }

}
