// eslint-disable-next-line
/* eslint-disable */
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class AmbulanciaModule extends VuexModule {
  errors = {};
  response = {};
  ambulancias = [] as Array<unknown>;

  get currentAmbulanciass(): Array<unknown> {
    return this.ambulancias;
  }
  get getErrorAmbulancias() {
    return this.errors;
  }
  get getResponseAmbulancia() {
    return this.response;
  }

  @Mutation
  [Mutations.SET_ERROR_CAT](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AMBULANCIAS](ambulancia) {
    this.ambulancias = ambulancia;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_AMBULANCIA_RESPONSE](res) {
    this.response = res;
    this.errors = {};
  }

  @Action
  [Actions.LIST_AMBULANCIAS](form) {
    return ApiService.get("/ambulancia/"+ form).then(({ data }) => {
      console.log("LIST_AMBULANCIASS data: " , data);
      this.context.commit(Mutations.SET_AMBULANCIAS, data);
    }).catch(({ response }) => {
      this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
    });
  }
  
  @Action
  [Actions.CREATE_AMBULANCIA](form) {
    console.log("CREATE_AMBULANCIA form: " , form);
    return ApiService.post("/ambulancia", form).then(({ data }) => {
      console.log("CREATE_AMBULANCIA data: " , data);
      this.context.commit(Mutations.SET_AMBULANCIA_RESPONSE, data);
    }).catch(({ response }) => {
      this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
    });
  }
  
  @Action
  [Actions.UPDATE_AMBULANCIA](form) {
    console.log("UPDATE_AMBULANCIA form: " , form);
    return ApiService.post("/update-ambulancia", form).then(({ data }) => {
      console.log("UPDATE_AMBULANCIA data: " , data);
      this.context.commit(Mutations.SET_AMBULANCIA_RESPONSE, data);
    }).catch(({ response }) => {
      this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
    });
  }
  
  @Action
  [Actions.DELETE_AMBULANCIA](form) {
    console.log("DELETE_AMBULANCIA form: " , form);
    return ApiService.post("/delete-ambulancia", form).then(({ data }) => {
      console.log("DELETE_AMBULANCIA data: " , data);
      this.context.commit(Mutations.SET_AMBULANCIA_RESPONSE, data);
    }).catch(({ response }) => {
      this.context.commit(Mutations.SET_ERROR_CAT, response.data.errors);
    });
  }

}
